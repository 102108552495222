import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'
/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
  <Box sx={styles.wrapper}>
    <div style={{textAlign: "center"}}>
    <Heading variant='h4' sx={styles.heading}>
    SE NON VINCIAMO, NON CI DEVI NULLA
    </Heading>
    <Heading variant='h2'>
    Riceviamo il pagamento quando lo ricevi tu
 </Heading>
 <Heading variant='h2' sx={styles.heading}>


    </Heading>
    </div>
    <Heading variant='h3' sx={styles.running} >
      <div classame="TestoSezione61">
    <div classname="TestoSezione6">La nostra commissione è dedotta dal risarcimento che otterremo per te, così non hai niente da perdere. La nostra commissione standard è del 25% + IVA. <b>Deve essere chiaro che se non otteniamo il risarcimento per te, tu non ci devi nulla!</b>
    </div>
    <div> <Link to='/commissioni'><b></b></Link>
    </div>
    </div>
    </Heading>
   
    <div style={{textAlign: "center"}}>


    <Heading variant='h2' sx={styles.heading}>
    
    </Heading>
    
    <div variant='buttons.group' sx={styles.buttons}>
      <Button as={Link} to='#ContactForm'>
        Chiedi Ora il Rimborso
      </Button>
    </div>
    </div>



  </Box>
)
