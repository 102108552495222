import React from 'react'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'
import { Heading, Button } from 'theme-ui'
import { Link } from 'gatsby'
import Box from './Sezione9.Box'

const Sezione9 = () => (
  <>
 <HeroBoxed.Wrapper>

     <div className="boxsezione9"> 
     <div style={{width:"100%", textAlign:""}}>
     <Heading variant='h1'>
     Abbiamo aiutato migliaia di persone a ricevere un risarcimento!
     </Heading>
</div>
     <div className="boxelementisezione9">
<Box titolo="Risarcito" nome="Francesco" testo=" Ero un po’ titubante inizialmente ma se dovessi riassumere la mia esperienza l"></Box>
<Box titolo="Risarcito" nome="Miriam" testo="❝Seri, veloci, onesti e fanno tutto ciò che promettono❞"></Box>
<Box titolo="Risarcito" nome="Claudia" testo="❝Davvero in Gamba! Rimborso da 600 € ricevuto❞"></Box>

  
    
    </div>
    </div>
  
    </HeroBoxed.Wrapper>
  
  </>
)



export default Sezione9
