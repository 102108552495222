import React from 'react'
import { Heading, Box } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
  <Box sx={styles.wrapper}>
    <Heading variant='h2' sx={styles.heading}>
    Perché scegliere  EURORIMBORSO®?
    </Heading>
    <Heading variant='h4' sx={styles.running}>
1. Gestiamo l'intero iter del risarcimento dall'inizio alla fine!<br></br><br></br>
2. Ti aggiorniamo constantemente sull'avanzamento della tua pratica<br></br><br></br>
3. Ci facciamo carico di tutte le spese <br></br><br></br>
4. Addebiteremo una piccola commissione solo e se verrai risarcito<br></br>    
</Heading>
    <Heading variant='h3'>Affidati ai professionisti di Eurorimborso</Heading>
  </Box>
)
