import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box } from 'theme-ui'
import Headings from '@components/Mdx/Headings'
import { Heading} from 'theme-ui'
import { FaStar } from 'react-icons/fa'

const styles = {
  image: image => ({
    display: [`none`, null, `block`],
    height: `120%`,
    width: `1/2`,
    minWidth: 380,
    maxHeight: 514,
    borderRadius: `default`,
    backgroundImage: `url(${image})`,
    backgroundRepeat: `no-repeat`,
    // filter: `grayscale(1)`,
    overflowX: `hidden`,
    position: `absolute`,
    bottom: 0,
    right: 0,
    zIndex: 2
  })
}

export default (props) => {
return(
 
<div className="boxsezione9singolo">
<div className='sezione9titolo'><Heading variant="h3">{props.titolo}</Heading></div>
<div className='sezione9nome'><Heading variant="h4">{props.nome}</Heading></div>
<div className='sezione9stelline'><FaStar></FaStar><FaStar></FaStar><FaStar></FaStar><FaStar></FaStar><FaStar></FaStar></div>
<div className='sezione9testo'><p>{props.testo}</p></div>
  </div>
)

}

const testimonialQuery = graphql`
  query TestimonialQuery {
    file(absolutePath: { regex: "/numero1.(jpeg|jpg|gif|png)/" }) {
      publicURL
    }
  }
`
