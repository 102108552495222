import React from 'react'
import { Layout, Stack, Main } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Testimonial from '@widgets/Testimonial'
import Sezione3 from '@widgets/Sezione3'
import Sezione4 from '@widgets/Sezione4'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Hero from '../components/Hero/Hero'
import Barra_bassa from '@widgets/Barra_bassa/Barra_bassa'
import Sezione20 from '@widgets/Sezione20'
import Sezione7 from '@widgets/Sezione7'
import Sezione8 from '@widgets/Sezione8'
import Sezione9 from '@widgets/Sezione9'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Sezione10 from '@widgets/Sezione10'
import Sezione11 from '@widgets/Sezione11'
import Faq from '@widgets/Faq/Faq'

const Posts = ({ data: { posts = {}, featuredPosts = {} }, ...props }) => {
  const { pageContext: { services = {} } = {} } = props

  return (
    <Layout {...props}>
<Barra_bassa>
  
</Barra_bassa>

      <Seo title='Eurorimborso' description="Rimborso Voli"/>
      <Divider />
      <Stack>
        <Main>
          <Hero />
        </Main>
      </Stack>
      <Divider />
     
      <Stack>
        <Main>
          <ContactForm />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <Sezione4 />
        </Main>
      </Stack>
      <Divider />

      <Stack>
        <Main>
          <Testimonial />
        </Main>
      </Stack>
      <Stack>
        <Main>
          <Sezione11 />
        </Main>
      </Stack>
      <Divider />

      <Divider />

      <Divider />
  

      <Stack>
        <Main>
          <Sezione20 />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <Sezione7 />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <Sezione9 />
        </Main>
      </Stack>
      <Stack>
        <Main>
          <ContactInfo />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <ContactForm />
        </Main>
      </Stack>
      <Stack>
        <Main>
          <Faq/>
        </Main>
      </Stack>
      <Stack>
       
        <Main>
          {posts.group.map(group => (
            <>
              <Divider />
              <CardList
                nodes={group.nodes}
                limit={4}
                variant='horizontal-md'
                title={group.categoryName}
                key={group.categoryName}
                columns={[1, 2, 2, 2]}
                withTitleLink
              />
            </>
          ))}
        </Main>
      </Stack>
      <Stack>
        <Main>
          {services.mailchimp && (
            <>
              <Divider />
              <NewsletterExpanded simple />
            </>
          )}
        </Main>
      </Stack>
    </Layout>
  )
}

export default Posts
