import React from 'react'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'
import Content from './Sezione20.Content'


const Sezione20 = () => (
  <HeroBoxed.Wrapper>
  <HeroBoxed.LeftColumn>
    <Content />
  </HeroBoxed.LeftColumn>

  <MemphisPattern />
</HeroBoxed.Wrapper>
)

export default Sezione20
