import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  }
}

export default () => (
  <>
    <Section>
      <Heading variant='h1' sx={styles.heading}>
      <span>Il tuo volo ha subito</span> un ritardo o è stato cancellato? 
      </Heading>
      <Heading variant='h3' sx={styles.running}>
      Ottieni fino a <b>600 € di risarcimento</b> per passeggero, a prescindere dal prezzo del biglietto. Se non vinciamo, non ci devi nulla!
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      <Button as={Link} to='#ContactForm'>
        AVVIA PRATICA
      </Button>
    </Box>
  </>
)
