import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box } from 'theme-ui'
import Headings from '@components/Mdx/Headings'
import { Heading} from 'theme-ui'
import { FaStar } from 'react-icons/fa'
import { useState, useEffect} from 'react';

const styles = {
  image: image => ({
    display: [`none`, null, `block`],
    height: `120%`,
    width: `1/2`,
    minWidth: 380,
    maxHeight: 514,
    borderRadius: `default`,
    backgroundImage: `url(${image})`,
    backgroundRepeat: `no-repeat`,
    // filter: `grayscale(1)`,
    overflowX: `hidden`,
    position: `absolute`,
    bottom: 0,
    right: 0,
    zIndex: 2
  })
}

export default (props) => {
const  [risposta,setRisposta]=useState("none")
  return(
    <div className='boxsezionefaqsingolo'>
  <div className='domanda' onClick={()=>risposta=="none"?setRisposta("block"):setRisposta("none")}><Heading variant="h3">{props.domanda}</Heading></div>
 {risposta=="none"?<div className='risposta'style={{display:'none'}}><Heading variant="h4">{props.risposta}</Heading></div>:<div className='risposta'style={{display:"block"}}><Heading variant="h4">{props.risposta}</Heading></div>} 
    </div>
  )

}

const testimonialQuery = graphql`
  query TestimonialQuery {
    file(absolutePath: { regex: "/numero1.(jpeg|jpg|gif|png)/" }) {
      publicURL
    }
  }
`
