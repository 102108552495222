import React from 'react'
import { Heading, Box, Button } from 'theme-ui'
import { Link } from 'gatsby'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
  <HeroBoxed.Wrapper>
    <Box sx={styles.wrapper}>
      <div style={{ textAlign: "left", lineHeight: "1.4em" }}>
        <Heading variant='h2' sx={styles.heading}>
          Ogni giorno aiutiamo i passeggeri a ottenere i loro risarcimenti.<br />
          Se hai domande, non esitare a contattarci
        </Heading>
        <Heading variant='h4'>
          Il viaggiatore vittima di ritardo del volo pari o superiore a 3 ore, di cancellazione del volo o a cui è stato negato l’imbarco per overbooking ha diritto a ricevere un risarcimento che va da €250 a €600, in base alla distanza chilometrica di percorrenza del volo.
          <br /><br />
          <li>€ 250 per tutte le tratte aeree inferiori o pari a 1500 chilometri;</li><br />
          <li>€ 400 per tutte le tratte aeree intracomunitarie superiori a 1500 chilometri e per tutte le altre tratte comprese tra 1500 e 3500 chilometri;</li><br />
          <li>€ 600 per le tratte aeree che non rientrano nelle lettere a o b.</li><br />
          <div>
          { /* <Link to='/commissioni'><b>Conosci i tuoi Diritti. Dai un'occhiata al regolamento</b></Link> */}
          </div>
        </Heading>
        <Heading variant='h2' sx={styles.heading}>
          Contattaci Ora per ricevere assistenza gratuita specializzata
        </Heading>
      </div>
      <div style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
      <div variant='buttons.group' sx={styles.buttons}>
      <Button as={Link} to='/commissioni'>
        GUARDA LE NOSTRE COMMISSIONI
      </Button>
    </div>
      </div>
    </Box>
  </HeroBoxed.Wrapper>
)
