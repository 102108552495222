import React from 'react'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'
import { Box, Heading, Button } from 'theme-ui'
import { Link } from 'gatsby'
import { FaMoneyBillWave } from 'react-icons/fa'
import { FaRegEdit } from 'react-icons/fa'
import { FaSistrix } from 'react-icons/fa'
import { FaFileUpload } from 'react-icons/fa'




const Sezione4 = () => (
  <>
  <HeroBoxed.Wrapper>


     <div className="boxsezione4"> 
     <div style={{width:"100%", textAlign:"center"}}>
     <Heading variant='h2'>
      
      
     Come chiedere il rimborso aereo
     </Heading>
</div>

     <div className="boxelementisezione4">
     <div className="">
      </div>
     <div style={{textAlign: "center", fontSize: "4em"}}>
     <FaRegEdit /><br></br>
     </div>     
    
      <div style={{textAlign: "center"}}>
      <Heading variant='h3'>
      
      Compila il Modulo
      
    </Heading>
    </div>
    <div style={{textAlign: "center"}}>
    
    Compila il form per scoprire se hai diritto al risarcimento. 
    
    </div>
     </div>
     <div className="boxelementisezione4">
     <div className="">
    </div>
    <div style={{textAlign: "center", fontSize: "4em"}}>
     <FaSistrix/><br></br>
     </div>  
      <div style={{textAlign: "center"}}>
      <Heading variant='h3'>
Analisi Gratuita 
    </Heading>
    </div>
    <div style={{textAlign: "center"}}>
    Ti comunicheremo entro 24h l'ammissibilità e l'importo del risarcimento.
    </div>
     </div>
     <div className="boxelementisezione4">
     <div className="">

</div>
<div style={{textAlign: "center", fontSize: "4em"}}>
     <FaFileUpload /><br></br>
     </div>  
      <div style={{textAlign: "center"}}>
      <Heading variant='h3'>
Inviaci i documenti
    </Heading>
    </div>
    <div style={{textAlign: "center"}}>
    Ti comunicheremo i documenti necessari per l'avvio della pratica di risarcimento. 
    </div>
     </div>
     <div className="boxelementisezione4">
     <div className="">

</div>
<div style={{textAlign: "center", fontSize: "4em", color:"green"}}>
     <FaMoneyBillWave /><br></br>
     </div>  
      <div style={{textAlign: "center"}}>
      <Heading variant='h3'>Ricevi il Risarcimento</Heading>
    </div>
    <div style={{textAlign: "center"}}>
    Se vinciamo riceverai i soldi del risarcimento 
    </div>
     </div>     
     <div style={{width: "100%", textAlign: "center", marginTop: "20px"}}>
  <Button as={Link} to='#ContactForm'>
  Non aspettare, Scopri subito quanto ti spetta!
      </Button>
      </div>




    </div>
  
  </HeroBoxed.Wrapper>
  
  </>
)


const testimonialQuery = graphql`
  query TestimonialQuery {
    file(absolutePath: { regex: "/testimonial.(jpeg|jpg|gif|png)/" }) {
      publicURL
    }
  }
  `
export default Sezione4
